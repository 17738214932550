import React from 'react';
import { Script } from 'gatsby';

export default (
  <>
    <Script async src="https://www.googletagmanager.com/gtag/js?id=AW-378758560"></Script>
    <Script>
      {`window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-378758560');`}
    </Script>
  </>
);
