import React, { FC, PropsWithChildren } from 'react';
import adRoll from './scripts/adRoll';
import bing from './scripts/bing';
import linkedin from './scripts/linkedin';
import hubspot from './scripts/hubspot';
import analytics from './scripts/analytics';
import reddit from './scripts/reddit';
import propensity from './scripts/propensity';
import zoominfo from './scripts/zoominfo';

const TrackingContext: FC<PropsWithChildren> = ({ children }) => {
  const scripts = (
    <>
      {adRoll}
      {bing}
      {linkedin}
      {hubspot}
      {analytics}
      {reddit}
      {propensity}
      {zoominfo}
    </>
  );
  return (
    <>
      {scripts}
      {children}
    </>
  );
};

export default TrackingContext;
