import React from 'react';
import { Script } from 'gatsby';

export default (
  <>
    <Script
      type="text/javascript"
      id="hs-script-loader"
      async
      defer
      src="//js.hs-scripts.com/22461953.js"
    ></Script>
    <Script>
      {`
window.addEventListener("message", function(event) {
  if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    window.dataLayer.push({
      'event': 'hubspot-form-success',
      'hs-form-guid': event.data.id
    });
  }
});
    `}
    </Script>
  </>
);
