import React from 'react';
import { Script } from 'gatsby';

export default (
<>
<Script 
    src="https://cdn.propensity.com/propensity/propensity_analytics.js" 
    crossOrigin="anonymous">
</Script>
<Script type="text/javascript">
    {`
        propensity="propensity-002434";
    `}
</Script>
</>
);
